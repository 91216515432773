// Here you can add other styles
body {
  height: 100vh;
}

#root {
  height: 100vh;
}

.N {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding-top: .2rem;
  font-size: .75rem;
  display: inline-block;
  background: darkmagenta;
  color: white;
  text-align: center;
}

.M {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding-top: .2rem;
  font-size: .75rem;
  display: inline-block;
  background: darkgreen;
  color: white;
  text-align: center;
}

.W {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding-top: .2rem;
  font-size: .75rem;
  display: inline-block;
  background: orange;
  color: black;
  text-align: center;
}

.A {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding-top: .2rem;
  font-size: .75rem;
  display: inline-block;
  background: darkblue;
  color: white;
  text-align: center;
}

.CLOSED {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding-top: .2rem;
  font-size: .75rem;
  display: inline-block;
  background: rgb(124, 124, 124);
  color: white;
  text-align: center;
}


#MN {
  border-radius: 10% !important;
  background-color: darkgreen !important;
  height: 35px !important;
  width: 35px !important;
  color: white !important;
}

#WH {
  border-radius: 10% !important;
  background-color: orange !important;
  height: 35px !important;
  width: 35px !important;
  color: white !important;
}

#NF {
  border-radius: 10% !important;
  background-color: purple !important;
  height: 35px !important;
  width: 35px !important;
  color: white;
}

#AMB {
  border-radius: 10% !important;
  background-color: darkblue !important;
  height: 35px !important;
  width: 35px !important;
  color: white;
}

#AMBS {
  border-radius: 10% !important;
  height: 35px !important;
  width: 35px !important;
  color: white !important;
  background-color: darkred !important;
}

#VO {
  border-radius: 10% !important;
  background-color: darkslategray !important;
  height: 35px !important;
  width: 35px !important;
  color: white !important;
}

#e-MN {
  border-radius: 10% !important;
  background-color: darkgreen !important;
  height: 35px !important;
  width: 35px !important;
  color: white !important;
}

#e-WH {
  border-radius: 10% !important;
  background-color: orange !important;
  height: 35px !important;
  width: 35px !important;
  color: white !important;
}

#e-NF {
  border-radius: 10% !important;
  background-color: purple !important;
  height: 35px !important;
  width: 35px !important;
  color: white;
}

#e-FA {
  border-radius: 10% !important;
  background-color: rgb(19, 206, 12) !important;
  height: 35px !important;
  width: 35px !important;
  color: white;
}

#e-AMB {
  border-radius: 10% !important;
  background-color: darkblue !important;
  height: 35px !important;
  width: 35px !important;
  color: white;
}

#e-AMBS {
  border-radius: 10% !important;
  height: 35px !important;
  width: 35px !important;
  color: white !important;
  background-color: darkred !important;
}

#e-VO {
  border-radius: 10% !important;
  background-color: darkslategray !important;
  height: 35px !important;
  width: 35px !important;
  color: white !important;
}

#FA {
  border-radius: 10% !important;
  background-color: rgb(20, 167, 255) !important;
  height: 35px !important;
  width: 35px !important;
  color: white !important;
}

#PE {
  border-radius: 10% !important;
  background-color: rgb(109, 80, 0) !important;
  height: 35px !important;
  width: 35px !important;
  color: white !important;
}

.number {
  position: relative;
  left: 15px;
  top: 5px;
  background-color: black;
  border-radius: 50%;
  height: 27px;
  padding-top: 2px;
  margin-top: -10px;
  width: 27px;
}

.seat {
  border-radius: 8px;
  width: 2em;
  height: 2em;
  padding-top: .2em;
  font-size: .75rem;
  display: inline-block;
  background: rgb(0, 8, 121);
  color: white;
  text-align: center;
  margin-top: -0.2em;
  margin-left: 0.3em;
  margin-right: 0.3em;
}

.seat.removed {
  border-radius: 8px;
  width: 2em;
  height: 2em;
  padding-top: 0.25rem;
  font-size: 1rem;
  display: inline-block;
  background: rgb(102, 102, 102);
  color: white;
  text-align: center;
  margin-top: -0.2em;
  margin-left: 0.3em;
  margin-right: 0.3em;
}

.manifest table{
  border-style: 3px solid green;
}

.manifest th{
  font-weight: bold;
}

.bg-override {
  background-color: #f5f580 !important;
}

.bg-override-selected {
  background-color: #dcdc66 !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  cursor: wait !important;
  z-index: 10000;
}

.control-link {
  font-size: smaller;
}

.rider-alert-body {
  text-overflow: ellipsis;
  overflow: hidden;
}